import isEmpty from "lodash/isEmpty";
import addCollectionToNode from "utils/AddCollectionToNode";

import {
  ADD_LESSON,
  FOCUS_LESSON,
  FOCUS_SLIDE,
  NEXT_SLIDE,
  PREV_SLIDE,
} from "constants/Constants";

const lessons = {};
let selectedId = null;
let selectedSlideIndex = 0;

const initialState = {
  selectedId,
  selectedSlideIndex,
  lessons,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_LESSON:
      let { lesson } = action.payload.data;
      return {
        ...state,
        lessons: addCollectionToNode({ slug: lesson }, state.lessons, "slug"),
      };
    case FOCUS_LESSON:
      let id = action.payload.data.id;
      return {
        ...state,
        selectedId: id,
      };

    case FOCUS_SLIDE:
      id = action.payload.data.id;

      return {
        ...state,
        selectedSlideIndex: id,
      };
    case NEXT_SLIDE:
      let currentLesson = getSelectedLesson(state);
      let currentIndex = state.selectedSlideIndex;
      let slideCount = currentLesson.slides.length;
      let nextIndex = Math.min(currentIndex + 1, slideCount - 1);

      return {
        ...state,
        selectedId: nextIndex,
      };
    case PREV_SLIDE:
      currentLesson = getSelectedLesson(state);
      currentIndex = state.selectedSlideIndex;

      slideCount = currentLesson.slides.length;
      let prevIndex = Math.max(currentIndex - 1, 0);

      return {
        ...state,
        selectedSlideIndex: prevIndex,
      };
    default:
      return state;
  }
};

export const getSelectedLesson = state => {
  const key = state.selectedId;
  return state.lessons[key];
};

export const getSelectedSlide = state => {
  const index = state.selectedSlideIndex;

  // lesson not loaded.
  if (
    isEmpty(state.lessons) ||
    !getSelectedLesson(state) ||
    isEmpty(getSelectedLesson(state).slides)
  ) {
    return 0;
  }
  return getSelectedLesson(state).slides[index];
};

export const getPrevSlideUrl = state => {
  const selectedLesson = getSelectedLesson(state);
  const lessonUrl = selectedLesson.slug;
  const currentIndex = parseInt(getSelectedSlide(state).id, 10);

  if (currentIndex < 1) {
    return null;
  }

  const prevSlide = selectedLesson.slides[currentIndex - 1];
  const prevSlideId = prevSlide.id;
  return `/lessons/${lessonUrl}/${prevSlideId}`;
};

export const getNextSlideUrl = state => {
  const selectedLesson = getSelectedLesson(state);
  const lessonUrl = selectedLesson.slug;
  const currentIndex = parseInt(getSelectedSlide(state).id, 10);

  if (currentIndex >= selectedLesson.slides.length - 1) {
    return null;
  }

  const nextSlide = selectedLesson.slides[currentIndex + 1];
  const nextSlideId = nextSlide.id;
  return `/lessons/${lessonUrl}/${nextSlideId}`;
};

export const getIsLastSlide = state => {
  const currentIndex = parseInt(getSelectedSlide(state).id, 10);
  const selectedLesson = getSelectedLesson(state);
  if (!selectedLesson || isEmpty(selectedLesson.slides)) return false;

  const total = getSelectedLesson(state).slides.length;

  return currentIndex + 1 === total;
};

export const getCompleteUrl = state => {
  const selectedLesson = getSelectedLesson(state);
  const lessonUrl = selectedLesson.slug;

  return `/lessons/${lessonUrl}/complete`;
};

export const getUserProgress = state => {
  const currentIndex = parseInt(getSelectedSlide(state).id, 10);
  const selectedLesson = getSelectedLesson(state);
  if (!selectedLesson || isEmpty(selectedLesson.slides)) return 0;

  const total = getSelectedLesson(state).slides.length;
  const progress = Math.round((currentIndex / total) * 100);

  return progress;
};
