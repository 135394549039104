import map from "lodash/map";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import moment from "moment";

import { ON_SET_USERS, ADD_USER } from "constants/Constants";

let users = {};

const initialState = {
  users,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ON_SET_USERS:
      let { users } = action.payload.data;
      return {
        ...state,
        users,
      };
    case ADD_USER:
      let { user } = action.payload.data;
      return {
        ...state,
        users: {
          ...state.users,
          [user.key]: { ...user },
        },
      };
    default:
      return state;
  }
};

export const getUsers = state => {
  const timedUsers = map(state.users, user => {
    return { name: user.name, time: new Date(user.time), key: user.key };
  });
  const orderedUsers = orderBy(timedUsers, ["time", "name"], ["desc"]);
  const readableUsers = map(orderedUsers, user => {
    const then = moment(user.time);
    return {
      ...user,
      from: then.fromNow(),
    };
  });
  return readableUsers;
};

export const getUser = (state, key) => {
  const users = getUsers(state);
  const user = find(users, u => u.key === key);
  return user;
};
