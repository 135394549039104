import React, { PureComponent } from "react";
import { css } from "glamor";
import ClipLoader from "react-spinners/ClipLoader";

import { Centered } from "styles";

export default class Loader extends PureComponent {
  render() {
    return (
      <Centered {...css({ width: "100vw", height: "100vh" })}>
        <ClipLoader sizeUnit={"px"} size={100} color={"#123abc"} />
      </Centered>
    );
  }
}
