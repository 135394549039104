const initialState = {
  id: "0.0.2",
  env: process.env.NODE_ENV,
  releaseDate: "2019-02-01",
};

console.log(initialState);

export default function versionReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return {
        ...state,
      };
  }
}
