import { ON_NAME_CHANGE } from "constants/Constants";

let name = null;

const initialState = {
  name,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ON_NAME_CHANGE:
      let { name } = action.payload.data;
      return {
        ...state,
        name,
      };
    default:
      return state;
  }
};

export const getName = state => {
  return state.ui.name;
};
