import { combineReducers } from "redux";

import admin, * as fromAdmin from "reducers/admin";
import lessons, * as fromLessons from "reducers/lessons";
import ui, * as fromUI from "reducers/ui";
import version from "reducers/version";

const appReducer = combineReducers({
  admin,
  lessons,
  ui,
  version,
});

const rootReducer = (state, action) => {
  let newState = state;
  return appReducer(newState, action);
};

export default rootReducer;

export const getSelectedLesson = state =>
  fromLessons.getSelectedLesson(state.lessons);

export const getSelectedSlide = state =>
  fromLessons.getSelectedSlide(state.lessons);

export const getPrevSlideUrl = state =>
  fromLessons.getPrevSlideUrl(state.lessons);

export const getNextSlideUrl = state =>
  fromLessons.getNextSlideUrl(state.lessons);

export const getIsLastSlide = state =>
  fromLessons.getIsLastSlide(state.lessons);

export const getCompleteUrl = state =>
  fromLessons.getCompleteUrl(state.lessons);

export const getName = state => fromUI.getName(state.ui);

export const getUserProgress = state =>
  fromLessons.getUserProgress(state.lessons);

export const getUsers = state => fromAdmin.getUsers(state.admin);

export const getUser = (state, key) => fromAdmin.getUser(state.admin, key);
