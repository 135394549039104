import React from "react";
import { css } from "glamor";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import map from "lodash/map";

import typography from "utils/typography";

import { shadowless } from "styles";

let styles = {
  bmBurgerButton: {
    position: "fixed",
    width: typography.rhythm(1),
    height: typography.rhythm(1),
    right: typography.rhythm(1),
    top: typography.rhythm(1),
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmCrossButton: {
    height: typography.rhythm(1),
    width: typography.rhythm(1),
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenu: {
    background: "#373a47",
    padding: `${typography.rhythm(0.5)} ${typography.rhythm(0.5)}`,
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: typography.rhythm(1),
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.2)",
  },
};

class TopNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    const buttonStyle = css({
      color: "#fff",
      "&:hover": {
        textDecoration: "underline",
      },
    });

    const pages = [{ title: "Home", uid: "home", link: "/" }];

    return (
      <Menu
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
        right
        styles={styles}
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
      >
        {map(pages, page => {
          return (
            <Link
              onClick={() => this.closeMenu()}
              key={page.uid}
              {...shadowless}
              to={page.link}
              {...buttonStyle}
            >
              {page.title}
            </Link>
          );
        })}
      </Menu>
    );
  }
}

export default TopNav;
