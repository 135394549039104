/**********
 * LESSONS
 **********/

export const FOCUS_LESSON = "FOCUS_LESSON";
export const ADD_LESSON = "ADD_LESSON";

/**********
 * UI
 **********/

export const ON_NAME_CHANGE = "ON_NAME_CHANGE";

/**********
 * ADMIN
 **********/
export const ON_SET_USERS = "ON_SET_USERS";
export const ADD_USER = "ADD_USER";

/**********
 * SLICES
 **********/
export const FOCUS_SLIDE = "FOCUS_SLIDE";
export const NEXT_SLIDE = "NEXT_SLIDE";
export const PREV_SLIDE = "PREV_SLIDE";
