import React, { Component, Suspense, lazy } from "react";
import { Router, Route } from "react-router-dom";
import history from "./history";

import { Switch } from "react-router";
import { TypographyStyle, GoogleFont } from "react-typography";

import Loader from "components/atoms/loader";
import typography from "utils/typography";
import ScrollToTop from "utils/ScrollToTop";
import TopNav from "components/TopNav";

import "./App.css";
const Lessons = lazy(() => import("./pages/Lessons"));
const Lesson = lazy(() => import("./pages/Lesson/index"));
const LessonComplete = lazy(() => import("./pages/Lesson/complete"));
const Admin = lazy(() => import("./pages/Admin/index"));
const User = lazy(() => import("./pages/User/index"));

const gtag = window.gtag;
console.log("history ", history);

history.listen((location, action) => {
  if (action === "PUSH" && typeof gtag === "function") {
    gtag("config", "UA-117743172-3", {
      page_location: window.location.href,
      page_path: location.pathname,
    });
  }
});

class App extends Component {
  render() {
    return (
      <div className="App">
        <TypographyStyle typography={typography} />
        <GoogleFont typography={typography} />
        <Suspense fallback={<Loader />}>
          <Router basename={process.env.PUBLIC_URL} history={history}>
            <div id="outer-container">
              <TopNav />
              <div id="page-wrap">
                <ScrollToTop>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={props => <Lessons {...props} />}
                    />
                    <Route
                      exact
                      path="/lessons/:lessonId/complete"
                      component={props => <LessonComplete {...props} />}
                    />
                    <Route
                      path="/lessons/:lessonId/:slideId"
                      component={props => <Lesson {...props} />}
                    />
                    <Route
                      path="/lessons/:lessonId"
                      component={props => <Lesson {...props} />}
                    />
                    <Route
                      exact
                      path="/admin"
                      component={props => <Admin {...props} />}
                    />
                    <Route
                      path="/admin/users/:key"
                      component={props => <User {...props} />}
                    />
                  </Switch>
                </ScrollToTop>
              </div>
            </div>
          </Router>
        </Suspense>
      </div>
    );
  }
}

export default App;
